<template>
  <el-select
    v-model="serviceCenterModel"
    @change="serviceCenterChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.SERVICECENTER')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getServiceCenters"
    :loading="loading"
  >
    <el-option
      v-if="showAll"
      :value="null"
      :label="$t('COMMON.ALL_SERVICECENTER')"
    >
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="serviceCenter in serviceCenters"
      :key="serviceCenter.id"
      :value="serviceCenter.id"
      :label="`${serviceCenter.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "service-center-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    serviceCenter: {
      type: String,
      default: null,
      description: "Service center id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      serviceCenterModel: this.serviceCenter,
      serviceCenters: {},
    };
  },

  setup() {},

  created() {
    this.getServiceCenters(null, this.serviceCenter);
  },

  methods: {
    async getServiceCenters(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }

        await this.$store.dispatch("serviceCenters/list", params);
        const serviceCentersArr = await this.$store.getters[
          "serviceCenters/list"
        ];
        this.serviceCenters = {};
        serviceCentersArr.forEach((serviceCenter) => {
          this.serviceCenters[serviceCenter.id] = serviceCenter;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    serviceCenterChanged(serviceCenter) {
      this.$emit("serviceCenterChanged", serviceCenter);
    },
  },

  watch: {
    serviceCenter(serviceCenter) {
      if (serviceCenter) {
        this.serviceCenterModel = serviceCenter;
        if (serviceCenter !== this.serviceCenterModel) {
          this.getServiceCenters(null, serviceCenter);
        }
      } else {
        this.serviceCenterModel = null;
      }
    },
    filterOrganization(filterOrganization) {
      this.getServiceCenters();
    },
  },
};
</script>
