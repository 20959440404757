<template>
  <el-select
    v-model="warehouseModel"
    @change="warehouseChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.WAREHOUSE')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getWarehouses"
    :loading="loading"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="warehouse in warehouses"
      :key="warehouse.id"
      :value="warehouse.id"
      :label="`${warehouse.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "warehouse-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    isOnlyModels: {
      type: Boolean,
      default: false,
      description: "Can filter only models warehouse",
    },
    warehouse: {
      type: String,
      default: null,
      description: "Warehouse id",
    },
    warehousesArray: {
      type: Array,
      default: () => [],
      description: "Warehouses",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      warehouseModel: this.warehouse,
      warehouses: {},
    };
  },

  setup() {},

  created() {
    this.getWarehouses(null, this.warehouse);
  },

  methods: {
    async getWarehouses(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "-created_at",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }

        if (this.isOnlyModels) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              isModel: this.isOnlyModels,
            },
          };
        } else {
          params = {
            ...params,
            filter: {
              ...params.filter,
              isModel: false,
            },
          };
        }
        if (this.filterReseller) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.filterReseller },
          };
        }
        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }

        await this.$store.dispatch("warehouses/list", params);
        const warehousesArr = await this.$store.getters["warehouses/list"];
        this.warehouses = {};
        warehousesArr.forEach((warehouse) => {
          this.warehouses[warehouse.id] = warehouse;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    warehouseChanged(warehouseId) {
      const warehouse = Object.values(this.warehouses).find(
        (item) => item.id === warehouseId
      );
      if (this.multiple) {
        warehouseId = [...warehouseId].map((item) => ({
          type: "warehouses",
          id: item,
        }));
      }
      this.$emit("warehouseChanged", warehouseId, warehouse);
    },
  },

  watch: {
    warehouse(warehouse) {
      if (warehouse) {
        this.warehouseModel = warehouse;
        this.getWarehouses(null, warehouse);
      } else {
        this.warehouseModel = null;
      }
    },
    filterOrganization(filterOrganization) {
      this.getWarehouses();
    },

    warehousesArray(warehouses) {
      if (warehouses?.length) {
        warehouses.map((warehouse) => {
          if (!this.warehouseModel.includes(warehouse?.id?.toString())) {
            this.warehouseModel.push(warehouse?.id?.toString());
          }
        });
      }
    },
    filterReseller() {
      this.getWarehouses();
    },
  },
};
</script>
